import React, { JSX } from 'react';
import { Box, Spinner } from '@chakra-ui/react';

import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';

type MainPageContentProp = {
  children: React.ReactNode | React.ReactNode[];
};

export const MainPageContent = ({ children }: MainPageContentProp): JSX.Element => {
  const { isFetchingRestaurantUser } = useLocalizationContext();
  return (
    <>
      {isFetchingRestaurantUser ? (
        <Spinner />
      ) : (
        <Box textAlign="center" mt={[1, 1, 1, 16]} ml="-6px">
          {children}
        </Box>
      )}
    </>
  );
};

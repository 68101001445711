import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Input, List, ListItem, Text, useTheme } from '@chakra-ui/react';
import { getApplicationUsers, SearchUserType } from 'api/applicationUser';
import { ApplicationUser } from 'api/types';
import { useScannedRelevosContext } from 'contexts/ScannedRelevosContext';
import { useCombobox } from 'downshift';
import debounce from 'lodash.debounce';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { UserSelectMode } from './types';

type Props = {
  mode: UserSelectMode;
  placeholder?: string;
  maxLength?: number;
};

const Autocomplete = ({ placeholder, maxLength, mode }: Props): JSX.Element => {
  const [usersList, setUsersList] = useState<ApplicationUser[]>([]);
  const { setApplicationUser } = useScannedRelevosContext();
  const translations = useTranslations();
  const { colors } = useTheme();

  const showErrorToastIfUserEnteredEnoughChars = (query: string) => {
    if (query.toString().length === Number(maxLength)) {
      toast.error(<b>{translations('client_not_found')}</b>);
    }
  };

  const updateUsersList = debounce((query) => {
    const searchUserType = mode === UserSelectMode.UPGRADE ? SearchUserType.Card : undefined;

    getApplicationUsers(query, searchUserType)
      .then((applicationUsers) => {
        if (!applicationUsers.length) {
          setUsersList([]);
          showErrorToastIfUserEnteredEnoughChars(query);
        } else {
          setUsersList(applicationUsers);
        }
      })
      .catch(() => {
        showErrorToastIfUserEnteredEnoughChars(query);
      });
  }, 600);

  const { getMenuProps, getInputProps, getItemProps } = useCombobox({
    items: usersList,
    itemToString: (item) => item?.userId || '',
    onInputValueChange: ({ inputValue, type }) => {
      if (!inputValue) {
        setUsersList([]);
        setApplicationUser(null);
      } else if (inputValue.length >= 3 && type === useCombobox.stateChangeTypes.InputChange) {
        setApplicationUser(null);
        updateUsersList(inputValue);
      } else {
        setUsersList([]);
      }
    },
    defaultHighlightedIndex: 0,
    onSelectedItemChange: ({ selectedItem: newItem }) => {
      setApplicationUser(newItem as ApplicationUser);
    },
  });

  return (
    <Box mx="auto" textAlign="center" maxWidth="300px" position="relative">
      <Input
        px={2}
        _focus={{ borderColor: colors.orange[500] }}
        _placeholder={{ color: colors.grey[200] }}
        variant="flushed"
        color={colors.black}
        borderBottomWidth="1px"
        borderColor={colors.grey[200]}
        mb={4}
        isTruncated
        data-testid="autocomplete-input"
        placeholder={placeholder}
        maxLength={maxLength}
        {...getInputProps()}
      />
      <List
        overflowY="auto"
        zIndex={3}
        maxHeight="200px"
        color={colors.black}
        width="100%"
        position="absolute"
        bg={usersList.length ? colors.orange[500] : 'none'}
        borderRadius="lg"
        mb={4}
        py={2}
        {...getMenuProps()}
      >
        {usersList.map((item, index) => (
          <Box
            fontWeight="normal"
            color={colors.black}
            cursor="pointer"
            {...getItemProps({ item, index, key: item.userId })}
            mx={2}
            my={1}
          >
            <ListItem>
              <Text>{item.userId}</Text>
            </ListItem>
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default Autocomplete;

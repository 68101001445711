import React, { JSX } from 'react';
import Select, { SingleValue } from 'react-select';

import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import { selectStyle } from '../../../../common/components/Select/selectStyle';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { HistoryActionType } from '../../model/enums/historyActionType.enum';

type HistoryActionSelectProps = {
  onHistoryActionChange: (value: SingleValue<SelectOption>) => void;
  isTwoFactorReturnEnabled: boolean;
  isDepositEnabled: boolean;
  action?: HistoryActionType;
};

const HistoryActionSelect = ({
  action,
  isTwoFactorReturnEnabled,
  isDepositEnabled,
  onHistoryActionChange,
}: HistoryActionSelectProps): JSX.Element => {
  const translations = useTranslations();

  const historyActionSelectOptions: SelectOption[] = [
    { label: translations('history_action_select_all'), value: 'all' },
    { label: translations('history_action_select_borrow'), value: HistoryActionType.BORROW },
    { label: translations('history_action_select_return'), value: HistoryActionType.RETURN },
    ...(isTwoFactorReturnEnabled
      ? [{ label: translations('history_action_select_pending_return'), value: HistoryActionType.PENDING }]
      : []),
    ...(isDepositEnabled
      ? [
          { label: translations('history_action_select_deposit_borrow'), value: HistoryActionType.DEPOSIT_BORROW },
          { label: translations('history_action_select_deposit_return'), value: HistoryActionType.DEPOSIT_RETURN },
        ]
      : []),
  ];

  return (
    <Select<SelectOption, false>
      value={historyActionSelectOptions.find((option) => option.value === action) || historyActionSelectOptions[0]}
      styles={selectStyle}
      options={historyActionSelectOptions}
      onChange={onHistoryActionChange}
    />
  );
};

export default HistoryActionSelect;

import React, { JSX } from 'react';
import { Box, Flex, HStack, useTheme, VStack } from '@chakra-ui/react';
import { ScannedRelevosContextProvider } from 'contexts/ScannedRelevosContext';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { NoLiveLocationsHeader } from '../../common/components/NoActiveLocationsHeader/NoLiveLocationsHeader';
import TooltipHelp from '../../common/components/TooltipHelp';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../services/restaurants';
import { useLocalStorage } from '../../utils/LocalStorage.hook';
import RelevosSteps from './components/RelevosSteps/RelevosSteps';
import SelectAssignmentMode from './components/SelectAssignmentMode';
import AssignSumUp from './components/SumUp/AssignSumUp';
import UserSelect from './components/UserSelect';
import { ItemAssignmentSelectMode, UserSelectMode } from './components/UserSelect/types';
import { ScannerMode } from './model/enums/scanner-mode.enum';

const AssignRelevo = (): JSX.Element => {
  const translations = useTranslations();
  const { restaurantIds } = useRestaurantsData();
  const [selectItemMode, setSelectItemMode] = useLocalStorage<ItemAssignmentSelectMode>(
    'selectItemMode',
    ItemAssignmentSelectMode.SELECT_CATEGORY,
  );
  const { colors } = useTheme();

  return (
    <MainPageContent>
      {restaurantIds.length ? (
        <Box mb={2}>
          <MainPageHeading>{translations('relevos_assign_heading')}</MainPageHeading>

          <ScannedRelevosContextProvider mode={ScannerMode.ASSIGN}>
            <VStack spacing="20px">
              <Flex bgColor={colors.beige[50]} marginTop="18px" width="100%" wrap="wrap" px={6} py={4}>
                <Box height="100%" py={4} width={['100%', '100%', '100%', '100%', '50%']}>
                  <RelevosSteps
                    firstStep={translations('relevos_step_1')}
                    firstStepDescription={translations('relevos_step_1_description')}
                    secondStep={
                      <HStack>
                        <Box mr={1}>{translations('relevos_step_2')}</Box>
                        <TooltipHelp label={translations('relevos_assign_step_2_tooltip')} bgColor={colors.white} />
                      </HStack>
                    }
                    isUpgrade={false}
                  />
                  <SelectAssignmentMode defaultValue={selectItemMode} onChange={setSelectItemMode} />
                </Box>
                <Flex>
                  <UserSelect mode={UserSelectMode.ASSIGNMENT} scanMode={selectItemMode} />
                  <Box
                    width={['100%', '100%', '55%', '70%', '100%']}
                    display={['none', 'none', 'block', 'block', 'none']}
                    ml={5}
                  >
                    <AssignSumUp scanMode={selectItemMode} />
                  </Box>
                </Flex>
              </Flex>
              <Box width="100%" display={['block', 'block', 'none', 'none', 'block']}>
                <AssignSumUp scanMode={selectItemMode} />
              </Box>
            </VStack>
          </ScannedRelevosContextProvider>
        </Box>
      ) : (
        <NoLiveLocationsHeader />
      )}
    </MainPageContent>
  );
};

export default AssignRelevo;

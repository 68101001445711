export default {
  baseStyle: {
    table: {
      fontFamily: 'Rubik',
      borderCollapse: 'separate',
      borderSpacing: '0',
      textWrap: 'wrap',
    },
    tr: {
      th: {
        borderBottomColor: 'beige.50',
        backgroundColor: 'beige.10',
        fontSize: '14px',
        textAlign: 'left',
        fontFamily: 'Rubik',
        fontWeight: '900',
        color: 'grey.900',
        textTransform: 'none',
        _last: {
          textAlign: 'right',
        },
      },
      td: {
        color: 'grey.600',
        borderBottomColor: 'beige.50',
        fontSize: '14px',
        textAlign: 'left',
        _last: {
          textAlign: 'right',
        },
      },
      _last: {
        td: {
          border: 0,
        },
      },
    },
  },
  variants: {
    userProfile: {
      tr: {
        th: {
          borderBottomWidth: '1px',
        },
        td: {
          borderBottomWidth: '1px',
          _first: {
            fontWeight: '700',
          },
          _last: {
            textAlign: 'left',
          },
        },
      },
    },
    relevoSummary: {
      table: {
        borderBottomWidth: '1px',
        borderColor: 'orange.500',
      },
      tr: {
        th: {
          borderColor: 'orange.500',
          borderBottomWidth: '1px',
          textAlign: 'center',
          _last: {
            textAlign: 'center',
          },
        },
        td: {
          textAlign: 'center',
          _last: {
            textAlign: 'center',
          },
        },
      },
    },
  },
};

import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { RelevoSimpleTableContainer } from '../../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { LanguagesConfig } from '../../../../../contexts/LocalizationContext/types';
import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { DepositRelevoTableMode } from '../../../model/enums/deposit-relevo-table-mode.enum';

interface DepositRelevo {
  productName: string | null;
  category: {
    translations: LanguagesConfig;
  } | null;
  deposit?: string;
  error?: string;
  message?: string;
}

type DepositRelevoTableProps = {
  mode: DepositRelevoTableMode;
  items: DepositRelevo[];
};
export const DepositRelevoTable = ({ items, mode }: DepositRelevoTableProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  return (
    <RelevoSimpleTableContainer>
      <Table variant="relevoSummary" overflowX="auto">
        <Thead>
          <Tr>
            <Th>{translations('item_category')}</Th>
            <Th>{translations('item_name')}</Th>
            {mode === DepositRelevoTableMode.ERROR && <Th>{translations('error')}</Th>}
            {mode === DepositRelevoTableMode.SUCCESS && <Th>{translations('deposit')}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {items.map((relevo, index) => (
            <Tr key={index}>
              <Td>{relevo.category?.translations[language]}</Td>
              <Td>{relevo.productName}</Td>
              {mode === DepositRelevoTableMode.ERROR && (
                <Td minW="150px" maxW="200px">
                  {relevo.message}
                </Td>
              )}
              {mode === DepositRelevoTableMode.SUCCESS && <Td>{relevo.deposit}</Td>}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </RelevoSimpleTableContainer>
  );
};

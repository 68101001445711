import React, { JSX, ReactNode } from 'react';
import { Flex, Spacer, VStack } from '@chakra-ui/react';

import StepElement from './StepElement';

type RelevosStepsDefaultProps = {
  activeIndex: number;
  children: ReactNode[];
};

export const Stepper = ({ children, activeIndex }: RelevosStepsDefaultProps): JSX.Element => {
  return (
    <>
      <VStack alignItems="start" paddingRight="10px" my="20px">
        {children.map((child, index) => (
          <StepElement key={index} index={index} isActive={activeIndex === index} />
        ))}
      </VStack>
      <Flex direction="column">
        {/* FIY: workeround - we will change it to chakra stepper*/}
        {children.map((child, index) => (
          <React.Fragment key={index}>
            {!!index && <Spacer />}
            {child}
          </React.Fragment>
        ))}
      </Flex>
    </>
  );
};

export default Stepper;

import React from 'react';
import { Button, HStack, Input, Td, Tr, useNumberInput, useTheme } from '@chakra-ui/react';
import { RelevosProductCategoryWithImage } from 'api/types';

import { TableItemWithImage } from '../../../../common/components/TableItemWithImage';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';

const INCREMENT_BUTTON = '+';
const DECREMENT_BUTTON = '-';
const COMMON_FONT_SIZE = '16px';

const defaultButtonProps = {
  variant: 'ghost',
  fontSize: COMMON_FONT_SIZE,
  p: 0,
  m: 0,
  _focus: {
    boxShadow: 'none',
    backgroundColor: 'none',
  },
  _hover: {
    backgroundColor: 'none',
  },
  _active: {
    backgroundColor: 'none',
  },
};

type Props = {
  category: RelevosProductCategoryWithImage;
  updateQuantityOfItemsInCategory: (categoryId: string, quantity: number) => void;
  categoryQuantity: number;
};

const CategoriesListItem = ({ category, updateQuantityOfItemsInCategory, categoryQuantity }: Props): JSX.Element => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    max: 50,
    onChange: (valueAsString, valueAsNumber) => updateQuantityOfItemsInCategory(category.id, valueAsNumber),
    value: categoryQuantity,
  });
  const { colors } = useTheme();
  const { language } = useLocalizationContext();

  const incrementButtonProps = {
    ...getIncrementButtonProps(),
    ...defaultButtonProps,
  };
  const decrementButtonProps = { ...getDecrementButtonProps(), ...defaultButtonProps };
  const inputProps = { ...getInputProps(), p: 0, m: 0, fontSize: COMMON_FONT_SIZE };

  return (
    <Tr>
      <Td>
        <TableItemWithImage
          text={category.translations ? category.translations[language] : ''}
          imageUrl={category.image}
        />
      </Td>
      <Td minWidth="160px" maxWidth="160px">
        <HStack m={'auto'} mr={0}>
          <Button {...decrementButtonProps} color={colors.orange[500]}>
            {DECREMENT_BUTTON}
          </Button>
          <Input {...inputProps} border={0} textAlign="center" />
          <Button {...incrementButtonProps} color={colors.orange[500]}>
            {INCREMENT_BUTTON}
          </Button>
        </HStack>
      </Td>
    </Tr>
  );
};

export default CategoriesListItem;

import React, { JSX, useRef } from 'react';
import { AlertDialogFooter, Box, Button, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { RelevoInterface } from '../../../api/types';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import Dialog from '../ConfirmationDialog/Dialog';
import { RelevoSimpleTableContainer } from '../Table/TableHelper';
import { ItemsSummaryAccordion } from './ItemsSummaryAccordion';
import { ItemsSummaryStatusText } from './ItemsSummaryStatusText';

interface FailureItem {
  uId: string;
  product?: RelevoInterface;
  message?: string;
}

type ItemsSummaryModalProps = {
  isVisible: boolean;
  dialogHeader: string | JSX.Element | JSX.Element[];
  summaryText: string | JSX.Element | JSX.Element[];
  failureText: string | JSX.Element | JSX.Element[];
  onCancelClicked: () => void;
  failureItems?: FailureItem[];
  successItems?: unknown[];
};
const ItemsSummaryModal = ({
  isVisible,
  dialogHeader,
  summaryText,
  failureText,
  onCancelClicked,
  failureItems,
  successItems,
}: ItemsSummaryModalProps): JSX.Element | null => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const cancelRef = useRef(null);

  const handleCancel = () => {
    onCancelClicked();
  };
  return (
    <Dialog
      dialogHeader={dialogHeader}
      size="3xl"
      isOpen={isVisible}
      onClose={handleCancel}
      leastDestructiveRef={cancelRef}
      dialogFooter={
        <AlertDialogFooter justifyContent="flex-start">
          <Button colorScheme="orange" onClick={handleCancel}>
            {translations('ok')}
          </Button>
        </AlertDialogFooter>
      }
    >
      <ItemsSummaryStatusText
        summaryText={summaryText}
        successItems={successItems || []}
        failureItems={failureItems || []}
      />
      {failureItems?.length ? (
        <Box px={8} pt={4}>
          <ItemsSummaryAccordion buttonText={failureText} isOpen={true}>
            <RelevoSimpleTableContainer>
              <Table variant="relevoSummary" overflowX="auto">
                <Thead>
                  <Tr>
                    <Th>{translations('item_category')}</Th>
                    <Th>{translations('item_name')}</Th>
                    <Th>{translations('error')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {failureItems
                    ? failureItems.map((item) => (
                        <Tr key={item.uId}>
                          <Td>{item.product?.category.translations[language]}</Td>
                          <Td>{item.product?.productName}</Td>
                          <Td>{item.message}</Td>
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>
            </RelevoSimpleTableContainer>
          </ItemsSummaryAccordion>
        </Box>
      ) : null}
    </Dialog>
  );
};

export default ItemsSummaryModal;

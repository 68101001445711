export const HISTORY_QUERY_KEY = 'history';
export const PROFILE_QUERY_KEY = 'profile';
export const RESTAURANT_USER_QUERY_KEY = 'restaurantUser';
export const RESTAURANT_CATEGORIES_QUERY_KEY = 'restaurantCategories';
export const GAVE_OUT_RELEVOS_QUERY_KEY = 'gaveOutRelevos';
export const NEWS_QUERY_KEY = 'homepageNews';
export const INVENTORY_QUERY_KEY = 'inventory';
export const RESTAURANT_USER_SURVEYS_QUERY_KEY = 'restaurantUserSurveys';
export const DOCUMENTS_QUERY_KEY = 'documents';
export const FREQUENTLY_ASKED_QUESTIONS_QUERY_KEY = 'frequentlyAskedQuestions';
export const NOTIFICATION_SETTINGS_QUERY_KEY = 'notificationSettings';
export const META_CATEGORIES_QUERY_KEY = 'metaCategories';
export const DEPOSIT_QUERY_KEY = 'deposit';

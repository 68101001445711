import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';

import { returnRelevos } from '../../../../api/relevos';
import Dialog from '../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import colors from '../../../../styles/colors';
import RelevoScanner from '../RelevoScanner';
import ReturnStationList from './ReturnStationList';

const cancelButtonDefaultProps = {
  width: '250px',
  color: colors.white,
  backgroundColor: colors.red[500],
  fontSize: '28px',
  fontWeight: 'normal',
  borderRadius: '20px',
  padding: '25px',
  _focus: { boxShadow: 'none', backgroundColor: colors.red[500] },
  _hover: { backgroundColor: colors.red[500] },
  _active: { backgroundColor: colors.red[500] },
};

const acceptButtonDefaultProps = {
  color: colors.white,
  backgroundColor: colors.orange[500],
  fontSize: '28px',
  fontWeight: 'normal',
  borderRadius: '20px',
  padding: '25px',
  _focus: { boxShadow: 'none', backgroundColor: colors.orange[500] },
  _hover: { backgroundColor: colors.orange[500] },
  _active: { backgroundColor: colors.orange[500] },
};

const ReturnStationScanner = (): JSX.Element => {
  const translations = useTranslations();
  const [showScanner, setShowScanner] = useState(false);
  const { scannedRelevos, setScannedRelevos, assignedRelevos, setAssignedRelevos, restaurantId } =
    useScannedRelevosContext();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);

  const executeReturn = () => {
    const relevosUids = scannedRelevos.map((relevo) => relevo.uId);
    if (restaurantId) {
      returnRelevos(relevosUids, restaurantId).then((response) => {
        if (response.notAssignedItems.length) {
          toast.error(
            <b>
              {translations('unsuccessfully_returned')}: {response.notAssignedItems.join(', ')}
            </b>,
          );
        } else if (response.assignedItems.length) {
          setAssignedRelevos(response);
          setScannedRelevos([]);
          setShowScanner(false);
        } else {
          toast.error(<b>{translations('return_relevos_error')}</b>);
        }
      });
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setShowScanner(false);
    setScannedRelevos([]);
  };

  const handleShowScanner = () => {
    setShowScanner(true);
    setAssignedRelevos({ assignedItems: [], notAssignedItems: [] });
  };

  return (
    <>
      <VStack spacing="80px">
        {assignedRelevos?.assignedItems && assignedRelevos?.assignedItems.length > 0 && !showScanner && (
          <HStack spacing="20px">
            <h2 style={{ fontSize: '46px' }}>{translations('return_station_return_success')}</h2>
            <CheckCircleIcon color={colors.green[500]} w={8} h={8} />
          </HStack>
        )}
        {!showScanner && (
          <Button {...acceptButtonDefaultProps} onClick={() => handleShowScanner()}>
            {translations(
              assignedRelevos?.assignedItems && assignedRelevos?.assignedItems.length > 0
                ? 'return_station_return_more_button'
                : 'return_station_return_button',
            )}
          </Button>
        )}
      </VStack>
      {showScanner && (
        <Center width="90%">
          <VStack pr={12} width="70%" height="100%" spacing={scannedRelevos.length < 1 ? '110px' : '10px'}>
            <Box width="100%">
              {scannedRelevos.length < 1 && (
                <h2 style={{ fontSize: '34px' }}>{translations('return_station_explanation_info')}</h2>
              )}
              {scannedRelevos.length > 0 && <ReturnStationList scannedRelevos={scannedRelevos} />}
            </Box>
            <Flex width="100%">
              <Box>
                <Button {...cancelButtonDefaultProps} onClick={() => setIsOpen(true)}>
                  {translations('return_station_cancel_button')}
                </Button>
              </Box>
              <Spacer />
              <Box>
                <Button
                  {...acceptButtonDefaultProps}
                  width="250px"
                  disabled={scannedRelevos.length < 1}
                  onClick={() => executeReturn()}
                >
                  {translations('return_station_confirm_button')}
                </Button>
              </Box>
            </Flex>
          </VStack>
          <Box width="30%">
            <RelevoScanner />
          </Box>

          <Dialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            dialogFooter={
              <>
                <Button
                  {...acceptButtonDefaultProps}
                  width="250px"
                  margin="auto"
                  ref={cancelRef}
                  onClick={onClose}
                  title={translations('return_station_continue_button')}
                >
                  {translations('return_station_continue_button')}
                </Button>
                <Button
                  {...cancelButtonDefaultProps}
                  width="250px"
                  margin="auto"
                  title={translations('return_station_cancel_button')}
                  onClick={handleCancel}
                  ml={3}
                >
                  {translations('return_station_cancel_button')}
                </Button>
              </>
            }
          >
            <Text fontSize="30px">{translations('return_station_cancel_confirmation')}</Text>
          </Dialog>
        </Center>
      )}
    </>
  );
};

export default ReturnStationScanner;

import React, { JSX } from 'react';
import { Box, HTMLChakraProps, TableContainer, Text, useTheme } from '@chakra-ui/react';

type TableItemProps = {
  width: string;
  text?: string;
  textAlign?: 'left' | 'center' | 'right';
  fontSize?: string;
};

type TableHeaderProps = TableItemProps & { fontWeight?: string; fontColor?: string };

export const RelevoTableContainer = (props: HTMLChakraProps<'div'>): JSX.Element => {
  const { colors } = useTheme();
  const { children, ...otherProps } = props;
  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.orange[500],
      borderRadius: '24px',
    },
    scrollbarColor: `${colors.orange[500]} ${colors.white}`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scrollbarWidth: 'thin' as any,
  };
  return (
    <TableContainer border="1px solid" borderColor="beige.50" borderRadius="xl" css={scrollBarStyles} {...otherProps}>
      {children}
    </TableContainer>
  );
};

export const RelevoSimpleTableContainer = (props: HTMLChakraProps<'div'>): JSX.Element => {
  const { colors } = useTheme();
  const { children, ...otherProps } = props;
  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.orange[500],
      borderRadius: '24px',
    },
    scrollbarColor: `${colors.orange[500]} ${colors.white}`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scrollbarWidth: 'thin' as any,
  };
  return (
    <TableContainer css={scrollBarStyles} {...otherProps}>
      {children}
    </TableContainer>
  );
};

export const TableHeader = ({
  width,
  text,
  fontWeight = '500',
  textAlign = 'left',
  fontSize = '11px',
  fontColor = 'grey.200',
}: TableHeaderProps): JSX.Element => {
  return (
    <Box width={width} textAlign={textAlign}>
      <Text fontSize={fontSize} fontWeight={fontWeight} color={fontColor}>
        {text}
      </Text>
    </Box>
  );
};

export const TableItem = ({ width, text, textAlign = 'left', fontSize = '12px' }: TableItemProps): JSX.Element => {
  return (
    <Box width={width} textAlign={textAlign}>
      <Text fontSize={fontSize} color="black">
        {text}
      </Text>
    </Box>
  );
};

import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { AssignedRelevosResponse, RelevoInterface } from 'api/types';

import { ScannerMode } from '../../modules/RelevoAssignment/model/enums/scanner-mode.enum';
import { useRestaurantsData } from '../../services/restaurants';

type ScannedRelevos = RelevoInterface[];
interface ApplicationUser {
  id: string;
  userId: string;
  accountInfo?: {
    accountBalance: {
      amount: string;
      currency: string;
    };
    notReturnFeeSum: number;
  };
  paymentInformationValid?: boolean;
}

export interface ScannedRelevosContextInterface {
  applicationUser: ApplicationUser | null;
  restaurantId: string | null;
  scannedRelevos: ScannedRelevos;
  assignedRelevos: AssignedRelevosResponse | null;
  setAssignedRelevos: Dispatch<SetStateAction<AssignedRelevosResponse | null>>;
  setScannedRelevos: Dispatch<SetStateAction<ScannedRelevos>>;
  setApplicationUser: (user: ApplicationUser | null) => void;
  setRestaurantId: (restaurantId: string | null) => void;
  mode: ScannerMode;
}

export const ScannedRelevosContext = createContext<ScannedRelevosContextInterface | null>(null);

type ScannedRelevosContextProps = {
  mode: ScannerMode;
  children: React.ReactNode;
  locationId?: string;
};

export const ScannedRelevosContextProvider = ({ mode, children, locationId }: ScannedRelevosContextProps) => {
  const { restaurantIds } = useRestaurantsData();
  const defaultRestaurantId = useMemo(() => {
    if (locationId) {
      return locationId;
    }
    if (restaurantIds.length === 1) {
      return restaurantIds[0];
    }
    return null;
  }, [locationId, restaurantIds]);
  const [applicationUser, setApplicationUser] = useState<ApplicationUser | null>(null);
  const [restaurantId, setRestaurantId] = useState<string | null>(defaultRestaurantId);
  const [scannedRelevos, setScannedRelevos] = useState<ScannedRelevos>([]);
  const [assignedRelevos, setAssignedRelevos] = useState<AssignedRelevosResponse | null>(null);

  return (
    <ScannedRelevosContext.Provider
      value={{
        applicationUser,
        scannedRelevos,
        restaurantId,
        setScannedRelevos,
        setApplicationUser,
        setAssignedRelevos,
        setRestaurantId,
        assignedRelevos,
        mode,
      }}
    >
      {children}
    </ScannedRelevosContext.Provider>
  );
};

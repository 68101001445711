import React, { PropsWithChildren, useMemo } from 'react';
import Select, { GroupBase, MultiValue, MultiValueProps } from 'react-select';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantInfo } from '../../../contexts/SessionContext/SessionContext';
import { cutLongString } from '../../../utils/stringHelper';
import { SelectOption } from '../Select/model/selectOption';
import { selectStyle } from '../Select/selectStyle';

const INPUT_TRIMMED_VALUE_LIMIT = 23;

const MultiValues = ({
  children,
  ...props
}: PropsWithChildren<
  MultiValueProps<{ value: string; label: string }, true, GroupBase<{ value: string; label: string }>>
>) => {
  const translations = useTranslations();
  const { getValue, index, options } = props;
  if (index > 0) return null;

  const { length } = getValue();
  let inputValue;
  if (length === 1) {
    inputValue = cutLongString(getValue()[0].label, INPUT_TRIMMED_VALUE_LIMIT);
  }
  if (options.length === length && length !== 1) {
    inputValue = translations('all_locations', {
      '{{count}}': length.toString(),
    });
  }
  if (length > 1 && options.length !== length) {
    inputValue = translations('selected_locations', {
      '{{count}}': length.toString(),
    });
  }
  return <div>{inputValue}</div>;
};

type RestaurantMultiSelectProps = {
  selectedRestaurantIds: string[];
  userRestaurants: RestaurantInfo[];
  onSelectRestaurants: (value: MultiValue<SelectOption>) => void;
  isDisabled?: boolean;
};

const RestaurantMultiSelect = ({
  selectedRestaurantIds,
  userRestaurants,
  onSelectRestaurants,
  isDisabled,
}: RestaurantMultiSelectProps) => {
  const restaurantSelectOptions = useMemo(
    () =>
      userRestaurants.map((restaurant) => ({
        value: restaurant.id,
        label: restaurant.restaurantName,
      })),
    [userRestaurants],
  );

  return (
    <Select<SelectOption, true>
      menuPortalTarget={document.body}
      value={restaurantSelectOptions.filter((option) => selectedRestaurantIds.includes(option.value))}
      isMulti
      onChange={onSelectRestaurants}
      options={restaurantSelectOptions}
      styles={selectStyle}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ MultiValue: MultiValues }}
      isDisabled={userRestaurants.length === 1 || isDisabled}
      menuPlacement="auto"
    />
  );
};

export default RestaurantMultiSelect;

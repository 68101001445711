import { toast } from 'react-toastify';

import { assignRelevos, returnRelevos } from '../../../../api/relevos';
import { ApplicationUser, AssignedRelevosResponse } from '../../../../api/types';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { ScannedRelevosContextInterface } from '../../../../contexts/ScannedRelevosContext/ScannedRelevosContext';

type AssignRelevosReturnType = {
  scannedRelevos: ScannedRelevosContextInterface['scannedRelevos'];
  applicationUser: ApplicationUser | null;
  executeAssign: () => Promise<AssignedRelevosResponse | undefined>;
};

type ReturnRelevosReturnType = {
  executeReturn: () => Promise<AssignedRelevosResponse | undefined>;
};

export const useAssignRelevos = (
  applicationUser: ApplicationUser | null,
  scannedRelevos: ScannedRelevosContextInterface['scannedRelevos'],
): AssignRelevosReturnType => {
  const translations = useTranslations();
  const { setScannedRelevos, setAssignedRelevos, setApplicationUser, restaurantId } = useScannedRelevosContext();

  const executeAssign = async () => {
    if (applicationUser && restaurantId) {
      const relevosUids = scannedRelevos.map((relevo) => relevo.uId);
      const response = await assignRelevos(applicationUser.id, relevosUids, restaurantId);
      setAssignedRelevos(response);
      if (response?.notAssignedItems.length) {
        setScannedRelevos((prevState) => prevState.filter((relevo) => !response.assignedItems.includes(relevo.uId)));
      } else {
        setScannedRelevos([]);
        setApplicationUser(null);
        toast.success(
          translations('successfully_assigned', {
            '{{dishes-number}}': response.assignedItems.length?.toString(),
            '{{client}}': applicationUser.userId,
          }),
        );
      }
      return response;
    }
  };

  return {
    scannedRelevos,
    applicationUser,
    executeAssign,
  };
};

export const useReturnRelevos = (
  scannedRelevos: ScannedRelevosContextInterface['scannedRelevos'],
): ReturnRelevosReturnType => {
  const { setScannedRelevos, setAssignedRelevos, restaurantId } = useScannedRelevosContext();
  const translations = useTranslations();

  const executeReturn = async () => {
    const relevosUids = scannedRelevos.map((relevo) => relevo.uId);
    if (restaurantId) {
      const response = await returnRelevos(relevosUids, restaurantId);
      setAssignedRelevos(response);
      if (response.notAssignedItems.length) {
        setScannedRelevos((prevState) => prevState.filter((relevo) => !response.assignedItems.includes(relevo.uId)));
      } else {
        setScannedRelevos([]);
        toast.success(
          translations('successfully_returned', { '{{dishes-number}}': response.assignedItems.length?.toString() }),
        );
      }
      return response;
    }
  };

  return {
    executeReturn,
  };
};

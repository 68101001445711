import React from 'react';
import { Box } from '@chakra-ui/react';

import RestaurantMultiSelect from '../../../../common/components/RestaurantSelect/RestaurantMultiSelect';
import { RestaurantInfo } from '../../../../contexts/SessionContext/SessionContext';
import { useHistoryFilterContext } from '../../context/HistoryFilterContext/hooks/useHistoryFilterContext';
import DateRangeSelect from '../DateRangeSelect/DateRangeSelect';
import HistoryActionSelect from '../HistoryActionSelect/HistoryActionSelect';

type HistoryFiltersProps = {
  userRestaurants: RestaurantInfo[];
  isTwoFactorReturnEnabled: boolean;
  isDepositEnabled: boolean;
};

const HistoryFilters = ({
  userRestaurants,
  isTwoFactorReturnEnabled,
  isDepositEnabled,
}: HistoryFiltersProps): JSX.Element => {
  const { historyState, handleSelectRestaurants, handleSelectHistoryAction, handleSelectDatesRange } =
    useHistoryFilterContext();

  return (
    <>
      <Box pt={2} mr={2}>
        <RestaurantMultiSelect
          selectedRestaurantIds={historyState.restaurantIds}
          userRestaurants={userRestaurants}
          onSelectRestaurants={handleSelectRestaurants}
        />
      </Box>
      <Box pt={2} mr={2}>
        <HistoryActionSelect
          isTwoFactorReturnEnabled={isTwoFactorReturnEnabled}
          isDepositEnabled={isDepositEnabled}
          onHistoryActionChange={handleSelectHistoryAction}
          action={historyState.action}
        />
      </Box>
      <Box pt={2} mr={2}>
        <DateRangeSelect
          startDate={historyState.startDate}
          endDate={historyState.endDate}
          dateRange={historyState.dateRange}
          onDatesRangeChange={handleSelectDatesRange}
        />
      </Box>
    </>
  );
};

export default HistoryFilters;

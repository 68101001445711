import React, { JSX, useEffect, useRef } from 'react';
import ReactDomServer from 'react-dom/server';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useHtmlTranslations, useTranslations } from 'contexts/LocalizationContext';

import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import colors from '../../../../styles/colors';
import { ItemAssignmentSelectMode } from '../UserSelect/types';
import RelevosList from './RelevosList';
import SumUpConfirmButtons from './SumUpConfirmButtons';

type AssignSumUpProps = {
  scanMode?: ItemAssignmentSelectMode;
};

const AssignSumUp = ({ scanMode }: AssignSumUpProps): JSX.Element | null => {
  const translations = useTranslations();
  const htmlTranslations = useHtmlTranslations();
  const { applicationUser, scannedRelevos, setScannedRelevos } = useScannedRelevosContext();

  const scrollToBottomRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollToBottomRef.current) {
      scrollToBottomRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (scannedRelevos.length === 1) {
      scrollToBottom();
    }
  }, [scannedRelevos]);

  if (scannedRelevos.length === 0) {
    if (applicationUser) {
      return (
        <>
          <Box pl={4} textAlign="left" ml="6px" pt={1}>
            {scanMode === ItemAssignmentSelectMode.SCAN_ITEM ? (
              <Text fontSize="20px" mb={4}>
                {htmlTranslations('relevos_assign_header', {
                  '{{dishes-count}}': ReactDomServer.renderToString(
                    <Text as="b" color={colors.orange[500]}>
                      {scannedRelevos.length}
                    </Text>,
                  ),
                  '{{user-id}}': ReactDomServer.renderToString(
                    <Text as="b">{applicationUser.userId?.toString()}</Text>,
                  ),
                })}
              </Text>
            ) : null}
            <Text fontSize="20px">
              {translations(
                scanMode === ItemAssignmentSelectMode.SELECT_CATEGORY
                  ? 'assign_relevos_select_quantities_info'
                  : 'assign_relevos_info',
              )}
            </Text>
            <Text fontSize="16px">
              {translations(
                scanMode === ItemAssignmentSelectMode.SELECT_CATEGORY
                  ? 'assign_relevos_select_quantities_extended_info'
                  : 'return_relevos_info_extended',
              )}
            </Text>
          </Box>
        </>
      );
    }
  }

  const removeRelevo = (relevo: string) => {
    setScannedRelevos((prevState) => prevState.filter((el) => el.uId !== relevo));
  };

  if (scannedRelevos.length >= 1 && scanMode !== ItemAssignmentSelectMode.SELECT_CATEGORY) {
    return (
      <Box ref={scrollToBottomRef} backgroundColor={colors.white}>
        <Flex direction="column" pl={4} textAlign="left" pt={1}>
          <Flex justifyContent="space-between" alignItems="baseline" flexWrap="wrap">
            {applicationUser && (
              <Text fontSize="20px">
                {htmlTranslations('relevos_assign_header', {
                  '{{dishes-count}}': ReactDomServer.renderToString(
                    <Text as="b" color={colors.orange[500]}>
                      {scannedRelevos.length}
                    </Text>,
                  ),
                  '{{user-id}}': ReactDomServer.renderToString(
                    <Text as="b">{applicationUser.userId?.toString()}</Text>,
                  ),
                })}
              </Text>
            )}
            <Box display={['none', 'none', 'block', 'block', 'none']}>
              <SumUpConfirmButtons />
            </Box>
          </Flex>

          {applicationUser && <RelevosList onRemoveItem={removeRelevo} />}

          {applicationUser && (
            <Box display={['block', 'block', 'none', 'none', 'block']} mb={4}>
              <SumUpConfirmButtons />
            </Box>
          )}
        </Flex>
      </Box>
    );
  }
  return <></>;
};

export default AssignSumUp;

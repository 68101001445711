import React, { JSX, useEffect, useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import colors from '../../../../styles/colors';
import RelevosList from './RelevosList';
import SumUpConfirmButtons from './SumUpConfirmButtons';

const ReturnSumUp = (): JSX.Element | null => {
  const translations = useTranslations();
  const { scannedRelevos, setScannedRelevos } = useScannedRelevosContext();

  const scrollToBottomRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollToBottomRef.current) {
      scrollToBottomRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (scannedRelevos.length === 1) {
      scrollToBottom();
    }
  }, [scannedRelevos]);

  const removeRelevo = (relevo: string) => {
    setScannedRelevos((prevState) => prevState.filter((el) => el.uId !== relevo));
  };

  if (scannedRelevos.length === 0) {
    return (
      <>
        <Box textAlign="left" ml="6px" pt={4}>
          <Text fontSize="20px">{translations('return_relevos_info')}</Text>
          <Text fontSize="16px">{translations('return_relevos_info_extended')}</Text>
        </Box>
      </>
    );
  }

  if (scannedRelevos.length >= 1) {
    return (
      <Box ref={scrollToBottomRef} backgroundColor={colors.white}>
        <Flex direction="column" pl={1} textAlign="left" pt={4}>
          <Flex justifyContent="space-between" alignItems="baseline" flexWrap="wrap">
            <h4 style={{ fontSize: '20px' }}>{translations('returns')}</h4>
            <Box display={['none', 'none', 'block', 'block', 'none']}>
              <SumUpConfirmButtons />
            </Box>
          </Flex>
          <RelevosList onRemoveItem={removeRelevo} />
          <Box display={['block', 'block', 'none', 'none', 'block']} mb={4}>
            <SumUpConfirmButtons />
          </Box>
        </Flex>
      </Box>
    );
  }
  return <></>;
};

export default ReturnSumUp;

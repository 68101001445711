export enum Routes {
  HOME_PATH = '/',
  SIGNIN_PATH = '/signin',
  HISTORY_PATH = '/history',
  ASSIGN_RELEVO_PATH = '/assign-relevo',
  RETURN_RELEVO_PATH = '/return-relevo',
  RELEVO_CARD_UPGRADE = '/card-upgrade',
  CONTACT_PATH = '/contact',
  PROFILE_PATH = '/profile',
  RESET_PASSWORD_PATH = '/auth/reset-password',
  LOCATIONS_PATH = '/locations',
  REPORTS_PATH = '/reports',
  RETURN_STATION_PATH = '/return-station',
  CREATE_CUSTOMER_PATH = '/create-customer',
  REPORT_DAMAGED_ITEMS = '/damaged-items',
  INVENTORY_PATH = '/inventory',
  SURVEY_SUBMITTED = '/partner-survey-submitted',
  INFOPOINT = '/infopoint',
  FREQUENTLY_ASKED_QUESTIONS = '/faq',
  RESTAURANT_PATH = '/restaurant/:id',
  DEPOSIT_PATH = '/deposit',
  DEPOSIT_BORROW_PATH = '/deposit/borrow',
  DEPOSIT_RETURN_PATH = '/deposit/return',
}

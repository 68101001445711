import colors from '../../../styles/colors';

export const relevoAssignmentRestaurantSelectStyle = {
  // eslint-disable-next-line
  menu: (base: any) => ({
    ...base,
    zIndex: 1000,
  }),
  // eslint-disable-next-line
  control: (base: any, state: any) => ({
    ...base,
    borderTop: '0px !important',
    borderLeft: '0px !important',
    borderRight: '0px !important',
    borderRadius: '0 !important',
    borderBottom: `1px thin !important`,
    borderColor: state.isFocused ? colors.orange[500] : colors.grey[200],
    minHeight: '40px',
    height: '40px',
    boxShadow: '0 !important',
    fontStyle: 'Rubik',
    fontSize: '16px',
    textAlign: 'left',
    background: 'none',
    '&:hover': {
      boxShadow: '0 !important',
    },
  }),
  // eslint-disable-next-line
  dropdownIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  // eslint-disable-next-line
  clearIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  // eslint-disable-next-line
  option: (base: any, state: any) => ({
    ...base,
    minHeight: '30px',
    paddingTop: 6,
    paddingBottom: 6,
    fontStyle: 'Rubik',
    fontSize: '16px',
    textAlign: 'left',
    whiteSpace: 'normal',
    fontWeight: state.isSelected ? '600' : '100',
    background: colors.white,
    color: colors.black,
    '&:hover': {
      cursor: 'pointer',
      background: colors.grey[50],
    },
  }),
};

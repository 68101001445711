import React, { JSX, useRef } from 'react';
import ReactDomServer from 'react-dom/server';
import { Box, Button, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { useScannedRelevosContext } from 'contexts/ScannedRelevosContext';

import './RelevosList.css';

import { ConfirmDialogBody } from '../../../../common/components/ConfirmationDialog/ConfirmDialogBody';
import Dialog from '../../../../common/components/ConfirmationDialog/Dialog';
import ItemsSummaryModal from '../../../../common/components/ItemsSummaryModal/ItemsSummaryModal';
import { RelevoSimpleTableContainer } from '../../../../common/components/Table/TableHelper';
import { useHtmlTranslations, useTranslations } from '../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { ScannerMode } from '../../model/enums/scanner-mode.enum';
import { useAssignRelevos, useReturnRelevos } from './SumUp.hook';

const SumUpConfirmButtons = (): JSX.Element => {
  const translations = useTranslations();
  const htmlTranslations = useHtmlTranslations();
  const { language } = useLocalizationContext();
  const { applicationUser, scannedRelevos, setScannedRelevos, restaurantId, mode, assignedRelevos } =
    useScannedRelevosContext();

  const { executeAssign } = useAssignRelevos(applicationUser, scannedRelevos);
  const { executeReturn } = useReturnRelevos(scannedRelevos);

  const { isOpen: isSummaryModalOpen, onOpen: onOpenSummaryModal, onClose: onCloseSummaryModal } = useDisclosure();

  const confirmRemoveModal = useDisclosure();
  const confirmRemoveCancelRef = useRef(null);

  const confirmAssignModal = useDisclosure();
  const confirmAssignCancelRef = useRef(null);

  const removeRelevos = () => {
    confirmRemoveModal.onClose();
    setScannedRelevos(() => []);
  };

  const returnRelevos = async () => {
    const response = await executeReturn();
    if (response?.notAssignedItems.length) {
      onOpenSummaryModal();
    }
  };

  const handleConfirm = () => removeRelevos();

  const handleAssign = async () => {
    const response = await executeAssign();
    confirmAssignModal.onClose();
    if (response?.notAssignedItems.length) {
      onOpenSummaryModal();
    }
  };

  const confirmButtonText =
    mode === ScannerMode.RETURN ? translations('scan_return_items') : translations('scan_assign_items');
  const confirmClearAllButtonText =
    mode === ScannerMode.RETURN
      ? translations('scan_clear_all_returns_confirm')
      : translations('scan_clear_all_assignments_confirm');

  const itemsSummaryDialogHeader =
    mode === ScannerMode.RETURN
      ? translations('scanner_returned_items_summary_modal_header')
      : translations('scanner_assigned_items_summary_modal_header');

  const itemsSummarySummaryText = htmlTranslations(
    mode === ScannerMode.RETURN
      ? 'scanner_returned_items_summary_modal_success_content'
      : 'scanner_assigned_items_summary_modal_success_content',
    {
      '{{success-items-number}}': ReactDomServer.renderToString(
        <Text as="b">{(assignedRelevos?.assignedItems.length || 0).toString()}</Text>,
      ),
      '{{items-number}}': ReactDomServer.renderToString(
        <Text as="b">
          {((assignedRelevos?.assignedItems.length || 0) + (assignedRelevos?.notAssignedItems.length || 0)).toString()}
        </Text>,
      ),
    },
  );
  const itemsSummaryFailureText =
    mode === ScannerMode.RETURN
      ? translations('scanner_returned_items_summary_modal_failure_content')
      : translations('scanner_assigned_items_summary_modal_failure_content');

  return (
    <HStack textAlign="left" mt={2}>
      <Box pr="4px">
        <Button
          variant="orangeSolid"
          disabled={scannedRelevos.length < 1 || !restaurantId}
          title={confirmButtonText}
          onClick={() => (mode === ScannerMode.RETURN ? returnRelevos() : confirmAssignModal.onOpen())}
          minWidth={144}
          margin="auto"
        >
          {confirmButtonText}
        </Button>
      </Box>
      <Box>
        <Button
          variant="transparent"
          color="orange.500"
          fontSize="16px"
          aria-label={translations('scan_remove_relevo')}
          disabled={scannedRelevos.length < 1}
          title={translations('scan_clear_all_relevo')}
          onClick={confirmRemoveModal.onOpen}
          margin="auto"
          minWidth={144}
        >
          {translations('scan_clear_all_relevo')}
        </Button>
      </Box>

      <Dialog
        isOpen={confirmRemoveModal.isOpen}
        leastDestructiveRef={confirmRemoveCancelRef}
        onClose={confirmRemoveModal.onClose}
        onConfirm={handleConfirm}
        cancelText={translations('scan_remove_cancel')}
        confirmText={translations('scan_remove_confirmed')}
      >
        <Text>{confirmClearAllButtonText}</Text>
      </Dialog>

      <Dialog
        leastDestructiveRef={confirmAssignCancelRef}
        isOpen={confirmAssignModal.isOpen}
        onClose={confirmAssignModal.onClose}
        onConfirm={handleAssign}
        confirmText={translations('assign_items_confirmed')}
        cancelText={translations('cancel')}
        size="3xl"
      >
        <ConfirmDialogBody
          confirmText={translations('assign_items_confirm', { '{{user-id}}': applicationUser?.userId || '' })}
        >
          <RelevoSimpleTableContainer>
            <Table variant="relevoSummary" overflowX="auto">
              <Thead>
                <Tr>
                  <Th>{translations('item_category')}</Th>
                  <Th>{translations('item_name')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {scannedRelevos.map((relevo) => (
                  <Tr key={relevo.uId}>
                    <Td>{relevo.category.translations[language]}</Td>
                    <Td>{relevo.productName}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </RelevoSimpleTableContainer>
        </ConfirmDialogBody>
      </Dialog>
      <ItemsSummaryModal
        isVisible={isSummaryModalOpen}
        onCancelClicked={onCloseSummaryModal}
        dialogHeader={itemsSummaryDialogHeader}
        summaryText={itemsSummarySummaryText}
        failureText={itemsSummaryFailureText}
        successItems={assignedRelevos?.assignedItems}
        failureItems={assignedRelevos?.notAssignedItems}
      />
    </HStack>
  );
};

export default SumUpConfirmButtons;

import React, { JSX, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Box, Button, Text } from '@chakra-ui/react';
import { getRelevoByUId, verifyRelevosForUser } from 'api/relevos';
import { AxiosError } from 'axios';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useScannedRelevosContext } from 'contexts/ScannedRelevosContext';
import { StatusCodes } from 'http-status-codes';

import Dialog from '../../../../common/components/ConfirmationDialog/Dialog';
import { LinkButton } from '../../../../common/components/LinkButton';
import QrCodeScanner from '../../../../common/components/QrCodeScanner/QrCodeScanner';
import { HttpException } from '../../../../common/models/httpException';
import { extractUId } from '../../../../utils/qrCodesHelper';
import { ScannerMode } from '../../model/enums/scanner-mode.enum';

const RelevoScanner = (): JSX.Element | null => {
  const translations = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { scannedRelevos, setScannedRelevos, applicationUser, mode } = useScannedRelevosContext();
  const cancelRef = useRef(null);
  const [code, setCode] = useState('');
  const [showScanConfirmation, setShowScanConfirmation] = useState(false);

  const handleResponseError = (err: AxiosError<HttpException>) => {
    switch (err.response?.status) {
      case StatusCodes.BAD_REQUEST:
        setIsModalOpen(true);
        return;
      case StatusCodes.NOT_FOUND:
        toast.error(<b>{err.response?.data?.message || translations('something_went_wrong')}</b>);
        break;
      default:
        toast.error(<b>{translations('something_went_wrong')}</b>);
        break;
    }
  };

  const { mutate: getRelevo, isLoading: isLoadingGetRelevo } = useMutation(getRelevoByUId, {
    onSuccess: (response) => {
      setCode('');
      setScannedRelevos((relevos) => [...relevos, response]);
      setShowScanConfirmation(true);
      setTimeout(() => setShowScanConfirmation(false), 400);
    },
    onError: (err: AxiosError<HttpException>) => {
      handleResponseError(err);
    },
  });

  const { mutate: verifyRelevos, isLoading: isLoadingVerifyRelevos } = useMutation(verifyRelevosForUser, {
    onSuccess: (response) => {
      setScannedRelevos(response);
      setShowScanConfirmation(true);
      setTimeout(() => setShowScanConfirmation(false), 400);
      if (response.some((item) => item.uId === extractUId(code))) {
        setCode('');
      }
    },
    onError: (err: AxiosError<HttpException>) => {
      handleResponseError(err);
    },
  });

  const isAlreadyScanned = useCallback(
    (uId: string | null): boolean => {
      return scannedRelevos.some((item) => item.uId === uId);
    },
    [scannedRelevos],
  );

  const handleScan = async (qrCode: string | null) => {
    if (!qrCode) {
      return;
    }
    const uId = extractUId(qrCode);
    if (!uId) {
      toast.error(<b>{translations('scan_invalid_qr_code')}</b>);
      return;
    }
    if (isLoadingGetRelevo || isLoadingVerifyRelevos || isAlreadyScanned(uId)) {
      return;
    }
    if (mode === ScannerMode.ASSIGN) {
      if (!applicationUser) {
        return;
      } else {
        const relevosUIds = scannedRelevos.map((item) => item.uId);
        verifyRelevos({
          userId: applicationUser.id,
          previouslyScannedProductsUids: relevosUIds,
          uId,
        });
      }
    } else {
      getRelevo(uId);
    }
  };

  useEffect(() => {
    const uId = extractUId(code);
    if (isAlreadyScanned(uId)) {
      setCode('');
    }
    if (code.length) {
      handleScan(code);
    }
    // FYI: handleScan - consider using useEffectEvent once it is released in a stable version of React, and we upgrade it
    // https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
    // eslint-disable-next-line
  }, [code, setCode, isAlreadyScanned]);

  return (
    <>
      <Box py={4} height="100%">
        <QrCodeScanner onScan={setCode} showScanConfirmation={showScanConfirmation} />
      </Box>

      <Dialog
        leastDestructiveRef={cancelRef}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        dialogFooter={
          <>
            <Button
              variant="transparent"
              color="orange.500"
              minWidth={144}
              margin="auto"
              ref={cancelRef}
              onClick={() => {
                setIsModalOpen(false);
              }}
              title={translations('cancel')}
            >
              {translations('cancel')}
            </Button>
            <LinkButton
              variant="orangeSolid"
              to={`${Routes.RELEVO_CARD_UPGRADE}/${applicationUser?.userId}`}
              minWidth={144}
              margin="auto"
              title={translations('yes_please')}
              ml={3}
            >
              {translations('yes_please')}
            </LinkButton>
          </>
        }
      >
        <Text>{translations('balance_limit_reached')}</Text>
      </Dialog>
    </>
  );
};

export default RelevoScanner;

import React from 'react';
import { Box } from '@chakra-ui/react';

import { RadioCard } from '../../../../common/components/RadioCard';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { ItemAssignmentSelectMode } from '../UserSelect/types';

export const SelectAssignmentMode = (props: {
  defaultValue: ItemAssignmentSelectMode;
  onChange: (
    value: ((prevState: ItemAssignmentSelectMode) => ItemAssignmentSelectMode) | ItemAssignmentSelectMode,
  ) => void;
}): JSX.Element => {
  const { restaurantId, applicationUser } = useScannedRelevosContext();

  return (
    <Box ml={130} mr={30} mt="-10px">
      {restaurantId && applicationUser ? (
        <RadioCard
          options={Object.values(ItemAssignmentSelectMode)}
          name="AssignMode"
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
      ) : null}
    </Box>
  );
};

import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { RelevoTableContainer } from '../../../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { NotificationEnabled } from '../NotificationEnabled/NotificationEnabled';
import { NotificationFrequency } from '../NotificationFrequency/NotificationFrequency';
import { NotificationLocations } from '../NotificationLocations/NotificationLocations';
import { NotificationSettingsMenu } from '../NotificationSettingsMenu/NotificationSettingsMenu';

export const NotificationSettingsTable = () => {
  const translations = useTranslations();

  return (
    <RelevoTableContainer>
      <Table variant="userProfile">
        <Thead>
          <Tr>
            <Th width="50%">{translations('notification_settings_table_header')}</Th>
            <Th>
              <NotificationSettingsMenu />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{translations('notification_settings_table_enabled')}</Td>
            <Td>
              <NotificationEnabled />
            </Td>
          </Tr>
          <Tr>
            <Td>{translations('notification_settings_table_frequency')}</Td>
            <Td>
              <NotificationFrequency />
            </Td>
          </Tr>
          <Tr>
            <Td>{translations('notification_settings_table_locations')}</Td>
            <Td>
              <NotificationLocations />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </RelevoTableContainer>
  );
};

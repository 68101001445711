import { AssignedRelevosResponse, CategoryQuantity, RelevoInterface } from 'api/types';
import {
  apiPaths,
  getRelevoByUIdPath,
  getReturnRelevosPath,
  getUnknownRelevosPath,
  getUpgradeCardPath,
  getUserRelevosPath,
  getUserRelevosVerifyPath,
} from 'config/apiPaths';

import { Paginated } from '../common/models/paginated';
import { Language } from '../contexts/LocalizationContext/types';
import { HistoryQueryRequest } from '../modules/History/model/HistoryQueryRequest';
import { HistoryResponse } from '../modules/History/model/HistoryResponse';
import axios from './axios';

export const getRelevoByUId = async (uId: string): Promise<RelevoInterface> => {
  return axios.get<RelevoInterface>(getRelevoByUIdPath(uId)).then((response) => response.data);
};

export const assignRelevos = async (
  userId: string,
  relevos: string[],
  restaurantId: string,
): Promise<AssignedRelevosResponse> => {
  const requestData = {
    relevos,
  };

  return axios
    .post<AssignedRelevosResponse>(getUserRelevosPath(userId, restaurantId), requestData)
    .then((response) => response.data);
};

export const assignUnknownRelevos = async (
  userId: string,
  categories: CategoryQuantity,
  restaurantId: string,
): Promise<number> => {
  const requestData = {
    categories,
  };

  return axios.post(getUnknownRelevosPath(userId, restaurantId), requestData).then((response) => response.data);
};

export const returnRelevos = async (relevosUids: string[], restaurantId: string): Promise<AssignedRelevosResponse> =>
  axios.post(getReturnRelevosPath(restaurantId), { items: relevosUids }).then((response) => response.data);

export const upgradeCard = async (userId: string, amount: number, restaurantId: string): Promise<void> =>
  axios.post(getUpgradeCardPath(userId, restaurantId), { amount: amount.toString() }).then((response) => response.data);

export const verifyRelevosForUser = async ({
  userId,
  uId,
  previouslyScannedProductsUids,
}: {
  userId: string;
  uId: string;
  previouslyScannedProductsUids: string[];
}): Promise<RelevoInterface[]> => {
  return axios
    .get<RelevoInterface[]>(getUserRelevosVerifyPath(userId), {
      params: { previouslyScannedProductsUids, uId },
    })
    .then((response) => response.data);
};

export const getRelevosHistory = async (
  historyQueryParams: HistoryQueryRequest,
  offset: number,
  limit: number,
): Promise<Paginated<HistoryResponse>> => {
  return axios
    .get<Paginated<HistoryResponse>>(apiPaths.RELEVOS_HISTORY_PATH, {
      params: { ...historyQueryParams, offset, limit },
    })
    .then((response) => response.data);
};

export const getRelevosHistoryExport = async (
  queryParams: HistoryQueryRequest,
  language: Language,
): Promise<string> => {
  return axios
    .get<string>(apiPaths.RELEVOS_HISTORY_EXPORT_PATH, {
      params: queryParams,
      responseType: 'blob',
      headers: { 'x-localization': language },
    })
    .then((response) => response.data);
};

import React from 'react';

export const useLocalStorage = <T>(
  storageKey: string,
  fallbackState: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const storageValue = localStorage.getItem(storageKey);
  const [value, setValue] = React.useState<T>(storageValue ? JSON.parse(storageValue) : fallbackState);

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

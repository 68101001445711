import React, { JSX } from 'react';
import { Box } from '@chakra-ui/react';
import { ScannedRelevosContextProvider } from 'contexts/ScannedRelevosContext';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { NoLiveLocationsHeader } from '../../common/components/NoActiveLocationsHeader/NoLiveLocationsHeader';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../services/restaurants';
import { ScanReturn } from './components/ScanReturn/ScanReturn';
import { ScannerMode } from './model/enums/scanner-mode.enum';

const ReturnRelevo = (): JSX.Element => {
  const translations = useTranslations();
  const { restaurantIds, restaurants } = useRestaurantsData();

  return (
    <MainPageContent>
      {restaurantIds.length ? (
        <Box mb={2}>
          <MainPageHeading>{translations('relevos_return_heading')}</MainPageHeading>
          <ScannedRelevosContextProvider mode={ScannerMode.RETURN}>
            <ScanReturn restaurants={restaurants} />
          </ScannedRelevosContextProvider>
        </Box>
      ) : (
        <NoLiveLocationsHeader />
      )}
    </MainPageContent>
  );
};

export default ReturnRelevo;

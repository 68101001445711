import React, { JSX, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { Box, Image, VStack } from '@chakra-ui/react';
import QrScanner from 'qr-scanner';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { useLocalStorage } from '../../../utils/LocalStorage.hook';
import SwitchCamera from '../SwitchCamera/SwitchCamera';

const QR_SCANNER_CAMERA_NOT_FOUND = 'not found';

export type QrCodeScannerProps = {
  onScan: (data: string) => void;
  showScanConfirmation?: boolean;
};

const getImagePath = (imageName: string) => require(`../../../assets/images/${imageName}`);

const QrCodeScanner = ({ onScan, showScanConfirmation }: QrCodeScannerProps): JSX.Element => {
  const translations = useTranslations();
  const [devices, setDevices] = useState<QrScanner.Camera[]>([]);
  const [deviceId, setDeviceId] = useLocalStorage('deviceId', '');
  const [pageVisibility, setPageVisibility] = useState(document.visibilityState);

  const qrScannerRef = useRef<QrScanner | null>(null);

  const devicesSelectOptions = useMemo(
    () =>
      devices?.map((device) => ({
        value: device.id,
        label: device.label,
      })),
    [devices],
  );

  const getQrScanner = useCallback(
    (videoElement?: HTMLVideoElement) =>
      videoElement
        ? new QrScanner(
            videoElement as HTMLVideoElement,
            (result) => {
              if (result.data) {
                onScan(result.data);
              }
            },
            {
              preferredCamera: deviceId.length ? deviceId : undefined,
              calculateScanRegion: (video) => {
                return {
                  width: video.videoWidth,
                  height: video.videoHeight,
                };
              },
            },
          )
        : null,
    [deviceId, onScan],
  );

  const handleCameraError = useCallback(
    (error: any) =>
      toast.error(
        error.includes(QR_SCANNER_CAMERA_NOT_FOUND) ? translations('camera_devices_permission_error') : error,
      ),
    // FYI: translations in deps cause endless re-rendering
    // eslint-disable-next-line
    [],
  );

  const getPageVisibilityListener = () => setPageVisibility(document.visibilityState);

  useEffect(() => {
    document.addEventListener('visibilitychange', getPageVisibilityListener);
    return () => {
      document.removeEventListener('visibilitychange', getPageVisibilityListener);
      qrScannerRef.current?.stop();
      qrScannerRef.current?.destroy();
    };
  }, []);

  const stopMediaStreamTracks = () => {
    const videoElement = document.getElementById('video') as HTMLVideoElement;
    const mediaStream = videoElement.srcObject;
    if (mediaStream instanceof MediaStream) {
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      videoElement.srcObject = null;
    }
  };

  useEffect(() => {
    if (pageVisibility === 'hidden') {
      stopMediaStreamTracks();
      qrScannerRef.current?.stop();
      qrScannerRef.current?.destroy();
      qrScannerRef.current = null;
    } else {
      const videoElement = document.getElementById('video') as HTMLVideoElement;
      if (!qrScannerRef.current) {
        qrScannerRef.current = getQrScanner(videoElement);
        qrScannerRef.current
          ?.start()
          .then(() => {
            QrScanner.listCameras(true).then((cameras) => {
              setDevices(cameras);
            });
          })
          .catch(handleCameraError);
      }
    }
  }, [getQrScanner, pageVisibility, handleCameraError]);

  const handleSelectDevice = (deviceOption: SingleValue<{ value: string; label: string }>) => {
    if (deviceOption) {
      setDeviceId(deviceOption.value);
      qrScannerRef.current?.setCamera(deviceOption.value);
    }
  };

  return (
    <VStack my="auto">
      <Box position="relative">
        {showScanConfirmation ? (
          <Box position="absolute" left="50%" top="50%" zIndex={2} ml={-35} mt={-35}>
            <Image h={70} w={70} src={getImagePath('green-check-mark.png')} alt="green-check-mark.png" />
          </Box>
        ) : null}
        <video id="video" width="300" height="270" />
      </Box>
      <SwitchCamera
        selectedOption={devicesSelectOptions.find((option) => option.value === deviceId)}
        devicesSelectOptions={devicesSelectOptions}
        handleSelectDevice={handleSelectDevice}
      />
    </VStack>
  );
};

export default QrCodeScanner;

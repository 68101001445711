import React, { JSX, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Flex, HStack, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { HttpException } from 'common/models/httpException';
import { useTranslations } from 'contexts/LocalizationContext';

import { assignUnknownRelevos } from '../../../../api/relevos';
import { CategoryQuantity } from '../../../../api/types';
import { ConfirmDialogBody } from '../../../../common/components/ConfirmationDialog/ConfirmDialogBody';
import Dialog from '../../../../common/components/ConfirmationDialog/Dialog';
import { RelevoSimpleTableContainer } from '../../../../common/components/Table/TableHelper';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { useRestaurantCategories } from '../../../../services/categories';
import colors from '../../../../styles/colors';
import CategoriesList from './CategoriesList';

const acceptButtonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: colors.orange[500] },
  _hover: { backgroundColor: colors.orange[500] },
  _active: { backgroundColor: colors.orange[500] },
};

const SelectCategories = (): JSX.Element => {
  const [categoriesToCreate, setCategoriesToCreate] = useState<CategoryQuantity>({});
  const { restaurantId, applicationUser, setApplicationUser } = useScannedRelevosContext();
  const cancelRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [isLoading, setIsLoading] = useState(false);

  const updateQuantityOfItemsInCategory = (categoryId: string, quantity: number) => {
    const categoriesQuantities = { ...categoriesToCreate, [categoryId]: quantity };
    setCategoriesToCreate(
      Object.keys(categoriesQuantities)
        .filter((category) => categoriesQuantities[category] != 0)
        .reduce(
          (categoriesQuantitiesToUpdate, category) => ({
            ...categoriesQuantitiesToUpdate,
            [category]: categoriesQuantities[category],
          }),
          {},
        ),
    );
  };

  const { data: categories, isLoading: isLoadingCategories } = useRestaurantCategories(restaurantId || '');

  const executeUnknownAssign = () => {
    handleClose();
    if (restaurantId && applicationUser) {
      setIsLoading(true);
      assignUnknownRelevos(applicationUser.id, categoriesToCreate, restaurantId)
        .then((result) => {
          const expectedResult = Object.values(categoriesToCreate).reduce((a, b) => a + b);
          if (result === expectedResult) {
            toast.success(
              translations('successfully_assigned', {
                '{{dishes-number}}': result.toString(),
                '{{client}}': applicationUser.userId,
              }),
            );
            setCategoriesToCreate({});
            setApplicationUser(null);
          } else {
            toast.warn(
              translations('partial_successfully_assigned', {
                '{{dishes-number}}': result.toString(),
                '{{expected-dishes-number}}': expectedResult.toString(),
                '{{client}}': applicationUser.userId,
              }),
            );
          }
        })
        .catch((error: AxiosError<HttpException>) => {
          toast.error(<b>{error.response?.data.message || translations('something_went_wrong')}</b>);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (!applicationUser) {
    return <Box />;
  }

  return (
    <Box flex={1}>
      <Flex direction="column" pl={4} textAlign="left" pt={1}>
        {isLoadingCategories ? (
          <Spinner />
        ) : (
          <CategoriesList
            categories={categories}
            updateQuantityOfItemsInCategory={updateQuantityOfItemsInCategory}
            categoriesToCreate={categoriesToCreate}
          />
        )}
        <HStack textAlign="left" mt={6}>
          <Box pr="4px">
            <Button
              {...acceptButtonDefaultProps}
              color={colors.black}
              fontWeight="normal"
              disabled={
                Object.values(categoriesToCreate).every((x) => x === 0) ||
                !restaurantId ||
                !applicationUser?.id ||
                isLoading
              }
              colorScheme="orange"
              title={translations('scan_assign_items')}
              onClick={handleOpen}
              minWidth={144}
              margin="auto"
            >
              {!isLoading ? translations('scan_assign_items') : <Spinner />}
            </Button>
          </Box>
        </HStack>
        <Dialog
          leastDestructiveRef={cancelRef}
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={executeUnknownAssign}
          confirmText={translations('assign_items_confirmed')}
          cancelText={translations('cancel')}
          size="3xl"
        >
          <ConfirmDialogBody
            confirmText={translations('assign_items_confirm', { '{{user-id}}': applicationUser.userId })}
          >
            <RelevoSimpleTableContainer>
              <Table variant="relevoSummary" overflowX="auto">
                <Thead>
                  <Tr>
                    <Th>{translations('category_list_category')}</Th>
                    <Th>{translations('category_list_quantity')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.keys(categoriesToCreate)
                    .map((categoryId) => categories?.find((category) => categoryId === category.id))
                    .map((categoryToCreate, index) => (
                      <Tr key={index}>
                        <Td>{categoryToCreate?.translations[language]}</Td>
                        {categoryToCreate ? <Td>{categoriesToCreate[categoryToCreate.id]}</Td> : null}
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </RelevoSimpleTableContainer>
          </ConfirmDialogBody>
        </Dialog>
      </Flex>
    </Box>
  );
};

export default SelectCategories;

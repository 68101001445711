import React, { JSX } from 'react';
import { Box, HStack, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { RelevoInventoryResponse } from '../../../api/types';
import { RelevoTableContainer } from '../../../common/components/Table/TableHelper';
import { InventoryListItem } from './InventoryListItem';

type InventoryTableProps = {
  isTwoFactorReturnEnabled: boolean;
  restaurantData: RelevoInventoryResponse | null;
  fetchMetadata: {
    isError: boolean;
    isLoading: boolean;
  };
};

const InventoryTable = ({
  isTwoFactorReturnEnabled,
  restaurantData,
  fetchMetadata,
}: InventoryTableProps): JSX.Element => {
  const translations = useTranslations();

  if (fetchMetadata.isError) {
    return <Text color="orange.500">{translations('something_went_wrong')}</Text>;
  }

  return (
    <RelevoTableContainer>
      <Table style={{ tableLayout: 'fixed' }}>
        <Thead>
          <Tr>
            <Th>{translations('inventory_table_dish')}</Th>
            <Th textAlign="right">{translations('inventory_table_items_count')}</Th>
            {isTwoFactorReturnEnabled ? (
              <Th textAlign="right">{translations('inventory_table_pending_items_count')}</Th>
            ) : null}
            <Th>{translations('inventory_table_volume_per_week')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fetchMetadata.isLoading ? (
            <Tr>
              <Td />
              <Td>
                <HStack alignItems="center">
                  <Box width="100%">
                    <Spinner color="orange.500" />
                  </Box>
                </HStack>
              </Td>
              <Td />
            </Tr>
          ) : (
            restaurantData &&
            restaurantData.categoriesSummary.map((data, index) => (
              <InventoryListItem isTwoFactorReturnEnabled={isTwoFactorReturnEnabled} key={index} data={data} />
            ))
          )}
        </Tbody>
      </Table>
    </RelevoTableContainer>
  );
};

export default InventoryTable;

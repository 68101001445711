import React from 'react';
import { Box, Center, Flex, Image, useTheme } from '@chakra-ui/react';

import { Routes } from '../../../config/routes';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useSessionContext } from '../../../contexts/SessionContext';
import { LanguageSelect, SelectLanguageMode } from '../LanguageSelect/LanguageSelect';
import { LinkButton } from '../LinkButton';
import { Sidebar } from '../Navbar';
import { MenuMobile } from '../Navbar/Menu/MenuMobile';

const getIconPath = (iconName: string) => require(`../../../assets/icons/${iconName}`);

export const SIDEBAR_WIDTH = '299px';
const LAYOUT_WIDTH = '100%';

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { doesSessionExist } = useSessionContext();
  const { colors } = useTheme();
  const translations = useTranslations();

  return (
    <>
      {!doesSessionExist && (
        <Flex width="100%" zIndex={10} alignItems="end" position="absolute" justifyContent="flex-end">
          <LanguageSelect mode={SelectLanguageMode.DEFAULT} />
        </Flex>
      )}
      <Box display="flex" minHeight="100%">
        {doesSessionExist && (
          <>
            <Box minWidth={SIDEBAR_WIDTH} display={{ base: 'none', xl: 'block' }}>
              <Flex w="100%" zIndex={10} position="absolute">
                <Flex w="100%" zIndex={10} alignItems="end" justifyContent="flex-end" position="fixed">
                  <Center h="71px">
                    <LinkButton variant="orangeSolid" color={colors.white} to={Routes.FREQUENTLY_ASKED_QUESTIONS}>
                      {translations('menu_faq')}
                    </LinkButton>
                  </Center>
                  <LanguageSelect mode={SelectLanguageMode.DEFAULT} />
                </Flex>
              </Flex>
              <Sidebar />
            </Box>
            <Box display={{ xl: 'none' }}>
              <MenuMobile />
            </Box>
          </>
        )}

        <Box width="100%" display={{ base: 'none', lg: 'contents' }} sx={{ '@media print': { display: 'contents' } }}>
          <Box
            bgColor={colors.orange[500]}
            p={2}
            height="71px"
            width="100%"
            position="fixed"
            sx={{ '@media print': { position: 'static' } }}
            zIndex={9}
          >
            <Flex justifyContent="space-between" direction="row" height="100%">
              <Image
                src={getIconPath('relevo_logo_white.svg')}
                width="165px"
                height="100%"
                my="auto"
                ml={4}
                alt="Logo"
              />
            </Flex>
          </Box>
        </Box>

        <Box pl={2} pt={4} width={{ base: '100%', lg: LAYOUT_WIDTH }} pr={0}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;

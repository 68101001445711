import React from 'react';
import { Box, Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { RestaurantSelect } from '../../../../common/components/RestaurantSelect/RestaurantSelect';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { RestaurantInfo } from '../../../../contexts/SessionContext/SessionContext';
import colors from '../../../../styles/colors';
import { relevoAssignmentRestaurantSelectStyle } from '../../styles/RelevoAssignmentRestaurantSelectStyle';
import RelevoScanner from '../RelevoScanner';
import ReturnSumUp from '../SumUp/ReturnSumUp';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

type ScanReturnProps = {
  restaurants: RestaurantInfo[];
};

export const ScanReturn = ({ restaurants }: ScanReturnProps): JSX.Element => {
  const translations = useTranslations();
  const { restaurantId, setRestaurantId } = useScannedRelevosContext();

  return (
    <Box>
      <VStack spacing="20px">
        <Flex bgColor={colors.beige[50]} marginTop="18px" width="100%" wrap="wrap" pl={6} py={4}>
          <Box height="100%" py={4} width={['100%', '100%', '100%', '100%', '50%']}>
            <HStack>
              <img src={getIconPath('bowl.svg')} alt="bowl icon" />
              <img src={getIconPath('cup.svg')} alt="cup icon" style={{ marginBottom: '10px' }} />
              <Text textAlign="left" fontWeight="bold" fontSize="16px">
                {translations('return_scan_relevos_for_return')}
              </Text>
            </HStack>
          </Box>
          <Flex wrap="wrap">
            <Box pr={4}>
              <RelevoScanner />
            </Box>
            <Center>
              <RestaurantSelect
                userRestaurants={restaurants}
                restaurantId={restaurantId}
                setRestaurantId={setRestaurantId}
                styles={relevoAssignmentRestaurantSelectStyle}
              />
            </Center>
          </Flex>
        </Flex>
      </VStack>
      <ReturnSumUp />
    </Box>
  );
};

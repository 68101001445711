import React from 'react';
import { Box, Center, Flex, HStack, Text } from '@chakra-ui/react';

import { RestaurantSelect } from '../../../../../common/components/RestaurantSelect/RestaurantSelect';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { RestaurantInfo } from '../../../../../contexts/SessionContext/SessionContext';
import RelevoScanner from '../../../../RelevoAssignment/components/RelevoScanner';
import { relevoAssignmentRestaurantSelectStyle } from '../../../../RelevoAssignment/styles/RelevoAssignmentRestaurantSelectStyle';

const getIconPath = (iconName: string) => require(`../../../../../assets/icons/${iconName}`);

type DepositScannerProps = {
  restaurants: RestaurantInfo[];
};

export const DepositScanner = ({ restaurants }: DepositScannerProps) => {
  const translations = useTranslations();
  const { restaurantId, setRestaurantId } = useScannedRelevosContext();

  return (
    <Flex bgColor="beige.50" marginTop={4} width="100%" wrap="wrap" pl={6} py={4}>
      <Box height="100%" py={4} width={['100%', '100%', '100%', '100%', '50%']}>
        <HStack>
          <img src={getIconPath('bowl.svg')} alt="bowl icon" />
          <Box mb={3}>
            <img src={getIconPath('cup.svg')} alt="cup icon" />
          </Box>
          <Text textAlign="left" fontWeight="bold" fontSize="16px">
            {translations('borrow_deposit_step')}
          </Text>
        </HStack>
      </Box>
      <Flex wrap="wrap">
        <Box pr={4}>
          <RelevoScanner />
        </Box>
        <Center>
          <RestaurantSelect
            userRestaurants={restaurants}
            restaurantId={restaurantId}
            setRestaurantId={setRestaurantId}
            styles={relevoAssignmentRestaurantSelectStyle}
          />
        </Center>
      </Flex>
    </Flex>
  );
};

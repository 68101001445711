import React, { JSX } from 'react';
import { Td, Tr } from '@chakra-ui/react';

import { CategorySummary } from '../../../api/types';
import { TableItemWithImage } from '../../../common/components/TableItemWithImage';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { volumePerWeek } from '../utils';

type ListItemProps = {
  isTwoFactorReturnEnabled: boolean;
  data: CategorySummary;
};

export const InventoryListItem = ({ isTwoFactorReturnEnabled, data }: ListItemProps): JSX.Element => {
  const { language } = useLocalizationContext();

  return (
    <>
      <Tr>
        <Td>
          <TableItemWithImage text={data.translations ? data.translations[language] : ''} imageUrl={data.imageUrl} />
        </Td>
        <Td textAlign="right">{data.restaurantItemsCount.toString()}</Td>
        {isTwoFactorReturnEnabled ? <Td textAlign="right">{data.pendingReturnItemsCount.toString()}</Td> : null}
        <Td>{data.startingDate ? volumePerWeek(data.givenOutItemsCount, data.startingDate).toFixed(1) : '0.0'}</Td>
      </Tr>
    </>
  );
};

import React, { JSX, useMemo } from 'react';
import Select, { SingleValue } from 'react-select';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantInfo } from '../../../contexts/SessionContext/SessionContext';

type RestaurantSelectProps = {
  userRestaurants: RestaurantInfo[];
  restaurantId: string | null;
  setRestaurantId: (restaurantId: string | null) => void;
  styles: any;
};

export const RestaurantSelect = ({
  userRestaurants,
  restaurantId,
  setRestaurantId,
  styles,
}: RestaurantSelectProps): JSX.Element | null => {
  const translations = useTranslations();
  const restaurantSelectOptions = useMemo(
    () =>
      userRestaurants?.map((restaurant) => ({
        value: restaurant.id,
        label: restaurant.restaurantName,
      })),
    [userRestaurants],
  );

  const handleRestaurantChange = (option: SingleValue<{ value: string; label: string }>) => {
    if (option) {
      setRestaurantId(option.value);
    }
  };

  if (userRestaurants?.length === 1) return null;

  return (
    <Select
      value={restaurantId ? restaurantSelectOptions.find((restaurant) => restaurant.value === restaurantId) : undefined}
      placeholder={translations('restaurant_select_placeholder')}
      styles={styles}
      options={restaurantSelectOptions}
      onChange={handleRestaurantChange}
    />
  );
};

import React, { JSX } from 'react';
import { Box, HStack, useRadio, useRadioGroup, UseRadioProps } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import colors from '../../../styles/colors';

const RadioCardItem = (props: UseRadioProps & { children: React.ReactNode }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex="1">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="0"
        borderRadius="12px"
        fontWeight="bold"
        margin="-1px"
        color={colors.grey[100]}
        backgroundColor=""
        _checked={{
          bg: 'white',
          color: `${colors.orange[500]}`,
          borderColor: `${colors.orange[500]}`,
          borderWidth: '1px',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        p={1}
      >
        {props.children}
      </Box>
    </Box>
  );
};

type Props = {
  options: string[];
  name: string;
  defaultValue: string;
  onChange: (value: never) => void;
};

export const RadioCard = ({ options, name, onChange, defaultValue }: Props): JSX.Element => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });

  const translations = useTranslations();
  const group = getRootProps();

  return (
    <HStack {...group} border="1px solid" borderColor={colors.grey[100]} borderRadius="12px">
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCardItem key={value} {...radio}>
            {translations(value)}
          </RadioCardItem>
        );
      })}
    </HStack>
  );
};

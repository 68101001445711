import React, { JSX } from 'react';
import {
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { FullPageLoader } from '../../../../common/components/Loader/FullPageLoader';
import { Pagination } from '../../../../common/components/Pagination/Pagination';
import { RelevoTableContainer } from '../../../../common/components/Table/TableHelper';
import TableHeaderWithOrder from '../../../../common/components/TableHeaderWithOrder/TableHeaderWithOrder';
import { useLocalizationContext } from '../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useRelevosHistory } from '../../../../services/history';
import { formatDateTime } from '../../../../utils/dateHelper';
import { DepositResponse } from '../../../Deposit/model/deposit-response';
import { DEPOSIT_USER_RELEVO_ID } from '../../../Deposit/utils/deposit.consts';
import { useHistoryFilterContext } from '../../context/HistoryFilterContext/hooks/useHistoryFilterContext';
import { HistoryActionType } from '../../model/enums/historyActionType.enum';
import { HistoryOrderField } from '../../model/enums/historyOrderField.enum';
import { HISTORY_DEFAULT_TABLE_LIMIT } from '../../utils/history.consts';
import { buildHistoryQueryParams, getDepositHistoryActionLabel } from '../../utils/history.helper';

const HistoryTable = (): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const { colors } = useTheme();
  const { historyState, handleOrderBy, handlePage } = useHistoryFilterContext();

  const { data, isLoading, isFetching, isError } = useRelevosHistory(
    buildHistoryQueryParams(historyState),
    (historyState.page - 1) * HISTORY_DEFAULT_TABLE_LIMIT,
    HISTORY_DEFAULT_TABLE_LIMIT,
  );

  const handleOrderHistoryBy = (field: HistoryOrderField) => () => handleOrderBy(field);

  const getHistoryActionLabel = (action: HistoryActionType, deposit?: DepositResponse) => {
    switch (action) {
      case HistoryActionType.BORROW:
        return translations('history_filter_action_borrow');
      case HistoryActionType.RETURN:
        return translations('history_filter_action_return');
      case HistoryActionType.PENDING:
        return translations('history_filter_action_pending_return');
      case HistoryActionType.DEPOSIT_BORROW:
        return getDepositHistoryActionLabel(translations('history_filter_action_deposit_borrow'), deposit);
      case HistoryActionType.DEPOSIT_RETURN:
        return getDepositHistoryActionLabel(translations('history_filter_action_deposit_return'), deposit);
      default: {
        const exhaustiveCheck: never = action;
        throw new Error(exhaustiveCheck);
      }
    }
  };

  const getUserRelevoId = (userRelevoId: string) => {
    if (userRelevoId === DEPOSIT_USER_RELEVO_ID) {
      return translations('deposit');
    } else {
      return userRelevoId;
    }
  };

  return (
    <Box pr={2}>
      {data ? (
        <FullPageLoader show={isLoading || isFetching}>
          <RelevoTableContainer>
            <Table overflowX="auto">
              <Thead>
                <Tr>
                  <Th>
                    <TableHeaderWithOrder
                      onClick={handleOrderHistoryBy(HistoryOrderField.DATE_TIME)}
                      headerText={translations('history_table_date_time')}
                      showOrder={historyState.orderField === HistoryOrderField.DATE_TIME}
                      orderType={historyState.orderType}
                    />
                  </Th>
                  <Th>
                    <TableHeaderWithOrder
                      onClick={handleOrderHistoryBy(HistoryOrderField.ACTION)}
                      headerText={translations('history_table_action')}
                      showOrder={historyState.orderField === HistoryOrderField.ACTION}
                      orderType={historyState.orderType}
                    />
                  </Th>
                  <Th>
                    <TableHeaderWithOrder
                      onClick={handleOrderHistoryBy(HistoryOrderField.RESTAURANT_NAME)}
                      headerText={translations('history_table_restaurant_name')}
                      showOrder={historyState.orderField === HistoryOrderField.RESTAURANT_NAME}
                      orderType={historyState.orderType}
                    />
                  </Th>
                  <Th>{translations('history_table_dish')}</Th>
                  <Th>{translations('history_table_user_relevo_id')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.items.length > 0 ? (
                  data.items.map((element, index) => (
                    <Tr key={`${index}-${element.dateTime}-${element.product?.id}-${element.category?.id}`}>
                      <Td>{formatDateTime(new Date(element.dateTime))}</Td>
                      <Td>{getHistoryActionLabel(element.action, element.deposit)}</Td>
                      <Td>{element.restaurant?.restaurantName}</Td>
                      <Td>
                        <Flex>
                          <Center mr={2} minW="64px">
                            <Image
                              src={element.product?.category.imageUrl || element.category?.imageUrl || 'test'}
                              maxHeight="64px"
                              width="64px"
                            />
                          </Center>
                          <Center>
                            <VStack alignItems="start">
                              {element.product ? <Text fontWeight="bold">{element.product?.productName}</Text> : null}
                              <Text>
                                {element.product?.category.translations[language] ||
                                  element.category?.translations[language]}
                              </Text>
                            </VStack>
                          </Center>
                        </Flex>
                      </Td>
                      <Td>{getUserRelevoId(element.userRelevoId)}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={5} p={5}>
                      <Text textAlign="center" fontSize="14px">
                        {translations('no_data')}
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </RelevoTableContainer>
          {data.totalPages && data.totalPages > 1 ? (
            <Center>
              <Pagination page={historyState.page} totalPages={data.totalPages} onPageChanged={handlePage} />
            </Center>
          ) : null}
        </FullPageLoader>
      ) : (
        <Spinner />
      )}
      {isError ? <Text color={colors.orange[500]}>{translations('something_went_wrong')}</Text> : null}
    </Box>
  );
};

export default HistoryTable;
